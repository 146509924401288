import { getUserToken } from '~/services/supabase'
import { reportError } from '~/services/errors'
import { TranscribeChapterPayload, TranscribeChapterResponse } from '~/types/database/chapter'
import { APIError } from '~/types/api/errors'

const transcribe = async (payload: TranscribeChapterPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/chapter/transcribe-sequential', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })

    try{
      const result: TranscribeChapterResponse & APIError = await data.json()

      if (result.error) {
        const error = new Error(result.error.message)
        reportError(error)
      }

      return result
    }catch(e){
      reportError(e as Error)
    }
  }
}

export {
  transcribe
}