import { useParams } from '@solidjs/router'
import TranslationView from '~/components/translation-view/translation-view'
import { TranslationParams } from '~/types/routes/series/translation'

export default function Translation() {
  const { id } = useParams<TranslationParams>()
  return (
    <>
      <TranslationView chapterId={id} />
    </>
  )
}
