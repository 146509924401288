import TranslationsList from '~/components/translations-list/translations-list'
import CanvasView from '~/components/canvas-view/canvas-view'
import styles from '~/components/translation-view/translation-view.module.css'
import { Component, Show, onMount } from 'solid-js'
import { TranslationViewProps } from '~/components/translation-view/translation-view.interfaces'
import SidebarHeader from '~/components/sidebar-header/sidebar-header'
import { loadTranslations } from '~/services/current-document/loading/translations'
import { DocumentProps } from '~/editor/document/document.interfaces'
import { chapter, getChapterData, setChapter } from '~/services/current-document/chapter'
import Document from '~/editor/document/document'
import { getTranslations } from '~/services/database/translations'
import { getComments } from '~/services/database/comments'
import { getTranslationsExport } from '~/services/export/translations'
import { transcribe } from '~/services/current-document/process/transcribe'
import ValidateChapterStep from '~/components/validate-chapter-step/validate-chapter-step'
import SmallButton from '~/components/small-button/small-button'
import ExportIcon from '~/assets/icons/export.svg'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const TranslationView: Component<TranslationViewProps> = ({ chapterId }) => {
  onMount(() => {
    setLoadingState({
      loading: true
    })
  })

  const setIsLoading = (value: boolean) => {
    setLoadingState({
      loading: value
    })
  }

  const onDocumentReady = async (props: DocumentProps) => {
    const currentDocument = new Document(props)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    let translationsData = await getTranslations(chapterId)
    if (translationsData.length === 0){
      setLoadingState({
        loading: true,
        message: translations().loading.transcription
      })
      const result = await transcribe({
        id: chapterId
      })
      if (result?.success) {
        translationsData = await getTranslations(chapterId)
      }
    }   
    const commentsData = await getComments(chapterId)
    loadTranslations({
      currentDocument,
      chapterData,
      translationsData,
      commentsData,
      setIsLoading
    })
  }

  const onExportTranslations = async (event: MouseEvent) => {
    event.stopPropagation()
    setIsLoading(true)
    try{
      await getTranslationsExport(chapterId)
    }catch(error){
      console.error(error)
    }
    setIsLoading(false)
  }
  
  return (
    <div class={styles.translationView}>
      <CanvasView
        settings={{
          mode: 'translation'
        }}
        onReady={onDocumentReady}
      />
      <div class={styles.sideView}>
        <Show when={chapter()}>
          {chapter => (
            <SidebarHeader
              chapter={chapter()}
              mode='translation'
            />
          )}
        </Show>

        <TranslationsList mode='translation' />

        <ValidateChapterStep
          mode='translation'
          aside={(
            <>
              <SmallButton
                icon={ExportIcon}
                onClick={onExportTranslations}
              >
                {translations().chapters.row.exportTranslation}
              </SmallButton>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default TranslationView